import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PrivacidadeComponents from '@monorepo-amais/commons/components/privacidade'

const Privacidade = ({ data }) => <PrivacidadeComponents Layout={Layout} {...data}></PrivacidadeComponents>

export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(slug: { eq: "portal-de-privacidade" }) {
			id
			metadata {
				banner_destaque {
					titulo
					titulo_ingles
					imagem_destaque {
						url
					}
					descricao
					descricao_ingles
				}

				faq {
					question
					question_ingles
					answer
					answer_ingles
					channels
				}
				section_channels {
					title
					title_ingles
					subtitle
					subtitle_ingles
					select_options {
						label
						label_ingles
						url
						url_ingles
					}
					text
					text_ingles
				}
				message_footer
				message_footer_ingles
			}
		}
	}
`

export default Privacidade
